import LogRocket from 'logrocket';
import doIfNoDevelopment from './doIfNotDevelopment';

// eslint-disable-next-line no-undef
const heapId = process.env.HEAP_APP_ID;
// eslint-disable-next-line no-undef
const logrocketAppId = process.env.LOGROCKET_APP_ID;

const loadHeap = (heapLoaded, setHeapLoaded) => {
  if (!heapLoaded && typeof window !== 'undefined') {
    document.querySelectorAll('#heap').forEach(node => node.remove());
    document.querySelectorAll('#heapSrc').forEach(node => node.remove());
    delete window.heap;

    let head = document.querySelector('head');

    doIfNoDevelopment(() => {
      LogRocket.init(logrocketAppId);
    });
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', 'heap');
    script.type = 'text/javascript';
    script.innerHTML = `
    window.heap=window.heap||[], 
    heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.setAttribute('id', 'heapSrc'),r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
        heap.load(${heapId}, {secureCookie: true});`;
    head.appendChild(script);
    head.appendChild(script);
    let heapSrc = document.querySelector('#heapSrc');
    heapSrc.addEventListener('load', () => {
      doIfNoDevelopment(() =>
        LogRocket.getSessionURL(sessionURL => {
          window.heap.track('LogRocket', { sessionURL: sessionURL });
        })
      );
      setHeapLoaded(true);
    });
  }
};

export default loadHeap;
