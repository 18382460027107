import { useEffect } from 'react';

const requestIdleCallbackPolyfill = (function () {
  if (typeof window === 'undefined') {
    return;
  }
  if ('requestIdleCallback' in window) {
    return window.requestIdleCallback;
  } else
    return callBack => {
      setTimeout(() => {
        callBack();
      }, 5000);
    };
})();

const useRequestIdleCallback = (loadThirdPartyFunction, dependencies = []) => {
  useEffect(() => {
    requestIdleCallbackPolyfill(loadThirdPartyFunction);
  }, [loadThirdPartyFunction, ...dependencies]);
};

export default useRequestIdleCallback;
