import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import useLoginWithToken from '../utils/useLoginWithToken';
import useOnCompleteLogin from '../utils/useOnCompleteLogin';
import { useHeap } from '../state/ScriptsDataProvider';

const UrlParamsWrapper = ({ children }) => {
  const onCompleteLogin = useOnCompleteLogin();
  const { heapLoaded } = useHeap();
  const search = typeof window !== 'undefined' ? window.location.search : {};
  const loginWithToken = useLoginWithToken();

  const { show_login, quote_type, quote_step, quote_id, auth_token } =
    queryString.parse(search);

  useEffect(() => {
    if (!heapLoaded) {
      return;
    }
    if (show_login) {
      navigate('/', { state: { login: true } });
    }
    if (quote_type && auth_token) {
      let url;
      switch (quote_type) {
        case 'auto':
          url = '/quote-auto';
          break;
        case 'home':
          url = '/quote-home';
          break;
        case 'renter':
          url = '/quote-renter';
          break;
        default:
          url = '/';
          break;
      }
      loginWithToken({
        token: auth_token,
        onCompleted: ({ tokenAuth }) => {
          onCompleteLogin(tokenAuth, true);
          navigate(url, { state: { quote_step, quote_id } });
        },
        onError: error => {
          console.error(error);
          navigate('/?show_login=true');
        },
      });
    }
  }, [search, heapLoaded]);

  return <>{children}</>;
};

UrlParamsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UrlParamsWrapper;
