import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import loadHeap from '../utils/loadHeap';
import LogRocket from 'logrocket';
import doIfNoDevelopment from '../utils/doIfNotDevelopment';
import useRequestIdleCallback from '../../utils/useRequestIdleCallback';

export const ScriptsContext = createContext();
const Provider = ScriptsContext.Provider;

const ScriptsDataProvider = ({ children }) => {
  const [heapLoaded, setHeapLoaded] = useState(false);

  const handleHeapError = e => {
    console.error(e);
    LogRocket.captureException(e);
    setHeapLoaded(false);
  };

  const tryHeapFuncs = heapfuncs => {
    try {
      heapfuncs();
    } catch (e) {
      handleHeapError(e);
    }
  };
  useRequestIdleCallback(() => loadHeap(heapLoaded, setHeapLoaded), [heapLoaded]);

  const state = {
    handleHeapError,
    tryHeapFuncs,
    heapLoaded,
  };

  return <Provider value={state}>{children}</Provider>;
};

ScriptsDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const getHeap = () => {
  if (typeof window !== 'undefined') {
    return window.heap;
  } else return null;
};
export const useHeap = () => {
  const { tryHeapFuncs, heapLoaded } = useContext(ScriptsContext);
  const heap = getHeap();

  const resetIdentity = () => {
    tryHeapFuncs(heap.resetIdentity);
  };
  const identifyUser = ({ email, id, firstName }) => {
    tryHeapFuncs(() => {
      heap.identify(id);
      heap.addUserProperties({
        email: email,
        Name: firstName,
      });
      doIfNoDevelopment(() =>
        LogRocket.identify(id, {
          name: firstName,
          email: email,
        })
      );
    });
  };
  const track = (eventName, params) => {
    tryHeapFuncs(() => heap.track(eventName, params));
  };
  return { identifyUser, track, resetIdentity, heap, heapLoaded };
};

export default ScriptsDataProvider;
