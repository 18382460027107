import axios from 'axios';
// eslint-disable-next-line no-undef
export const REST_API_ENDPOINT = process.env.REST_API_ENDPOINT;

export function getYears() {
  return axios.get(`${REST_API_ENDPOINT}/api/v2/search/years`);
}

export function getMakes({ year }) {
  return axios.get(`${REST_API_ENDPOINT}/api/v2/search/makes/${year}`);
}

export function getModels({ year, make }) {
  return axios.get(`${REST_API_ENDPOINT}/api/v2/search/models/${year}/${make}`);
}

export function getStyles({ year, make, model }) {
  let encodedModel = encodeURIComponent(model);
  return axios.get(
    `${REST_API_ENDPOINT}/api/v2/search/trims/${year}/${make}/${encodedModel}`
  );
}
