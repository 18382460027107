import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const BreadcrumbsContext = createContext();
const Provider = BreadcrumbsContext.Provider;

const BreadcrumbsDataProvider = ({ children }) => {
  const [customStyles, setCustomStyles] = useState('');
  const [crumbs, setCrumbs] = useState([]);

  /**
   * Adds crumbs.
   * @param {Array.<{title: String, path: String}>} newCrumbs - Crumbs to add..
   */
  const createCrumbs = newCrumbs => {
    if (Array.isArray(newCrumbs)) {
      if (_.isEmpty(newCrumbs)) {
        setCrumbs([]);
        return;
      }
      let initialPath = [
        {
          title: 'Home',
          path: '/',
        },
        {
          title: 'Learning Center',
          path: '/learn',
        },
      ];
      let crumbsToSet = initialPath.concat(newCrumbs);
      if (!_.isEqual(crumbsToSet, crumbs)) {
        setCrumbs(crumbsToSet);
      }
    } else {
      throw new Error('crumbs is not an array');
    }
  };

  let contextValue = useMemo(
    () => ({
      customStyles,
      setCustomStyles,
      crumbs,
      setCrumbs,
      createCrumbs,
    }),
    [customStyles, setCustomStyles, crumbs]
  );

  return <Provider value={contextValue}>{children}</Provider>;
};

BreadcrumbsDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BreadcrumbsDataProvider;

export const useBreadcrumbs = () => {
  const { customStyles, setCustomStyles, crumbs, createCrumbs } =
    useContext(BreadcrumbsContext);

  return { customStyles, setCustomStyles, crumbs, createCrumbs };
};
