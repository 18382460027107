import { gql } from '@apollo/client';

const USER_DATA = gql`
  fragment UserData on User {
    id
    email
    authenticationToken
    firstName
    lastName
    partner {
      companyName
    }
    roles {
      name
    }
  }
`;

export const SIGN_UP = gql`
  ${USER_DATA}
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      user {
        ...UserData
      }
      errors
      success
    }
  }
`;

export const LOGIN = gql`
  ${USER_DATA}
  mutation login($input: LoginInput!) {
    login(input: $input) {
      user {
        ...UserData
      }
      success
      errors
    }
  }
`;

export const CREATE_PARTNER = gql`
  mutation createPartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      errors
      success
    }
  }
`;

export const TOKEN_AUTH = gql`
  ${USER_DATA}
  mutation tokenAuth($tokenAuthInput: TokenAuthInput!) {
    tokenAuth(input: $tokenAuthInput) {
      user {
        ...UserData
      }
      success
      errors
    }
  }
`;

export const SELECT_QUOTE = gql`
  mutation selectQuote($input: SelectQuoteInput!) {
    selectQuote(input: $input) {
      errors
      success
      result
    }
  }
`;

export const GET_RATES = gql`
  mutation getQuoteRates($input: GetQuoteRatesInput!) {
    getQuoteRates(input: $input) {
      errors
      rates
      success
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout(input: {}) {
      errors
      success
    }
  }
`;
export const CREATE_POLICY = gql`
  mutation createPolicy($input: CreatePolicyInput!) {
    createPolicy(input: $input) {
      policy {
        id
        insuranceName
        carrierId
        policyNumber
        firstName
        lastName
        otherPeopleCovered
        carsInsured
        costPerMonth
        coverageBeginDate
        coverageEndDate
        bodilyInjuryLiability
        propertyDamageLiability
        uninsuredMotorist
        comprehensive
        collision
        medicalPayments
        personalInjuryProtection
        additionalNotes
        emergencyRoadside
        addons
        policyType
        insuranceOptions
        carrierOptions
        addresses {
          streetName
          city
          state
          zip
        }
      }
      success
      errors
    }
  }
`;

export const CREATE_LEAD = gql`
  mutation createLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      quoteId
      success
      errors
    }
  }
`;

export const RENTER_FRAGMENT = gql`
  fragment BaseRenter on Renter {
    deductible
    personalProperty
    personalLiability
    medicalPayments
    contentsCoverage
    residence
    yearOccupied
    safetyFeatures
    centralBurglarAlarm
    centralFireAlarm
    effective
  }
`;

export const DEMOGRAPHIC_FRAGMENT = gql`
  fragment BaseDemographic on Quote {
    firstName
    lastName
    phone
    phoneType
    email
    address1
    address2
    addressCity
    addressZip
    addressState
    currentAddress1
    currentAddress2
    currentCity
    currentState
    currentZip
    dob
    gender
    maritalStatus
    industry
    occupation
    homeOrCondo
    garageAddress1
    garageAddress2
    garageAddressZip
    garageAddressCity
    garageAddressState
    previousAddress1
    previousAddress2
    previousAddressCity
    previousAddressState
    previousAddressZip
    rentOrOwn
    sameGaraged
    autoCoverageLevel
    currentCarrier
    currentCarrierLength
    healthCoverAccidentInj
    priorLiability
    continuousCoverage
    lengthAtDwelling
    effectiveDate
    whyNoInsurance
  }
`;

export const INCIDENT_FRAGMENT = gql`
  fragment BaseIncident on Incident {
    bodilyInjury
    date
    description
    driver
    vehicle
    incidentType
    prayerForJudgement
    propertyDamage
    lossValue
  }
`;

export const DRIVER_FRAGMENT = gql`
  fragment BaseDriver on Driver {
    id
    currentLicenseState
    dob
    education
    firstName
    gender
    industry
    lastName
    maritalStatus
    occupation
    ageFirstLicensed
    stateFirstLicensed
    healthcarePlan
  }
`;
export const VEHICLE_FRAGMENT = gql`
  fragment BaseVehicle on Vehicle {
    annualMileage
    id
    lengthOwned
    make
    model
    ownershipType
    primaryUse
    trim
    vin
    whereGaraged
    year
  }
`;
export const HOME_FRAGMENT = gql`
  fragment BaseHome on Home {
    dwellingUse
    purchaseDate
    purchasePrice
    underConstruction
    yearBuilt
    ratingInfoDwelling
    squareFootage
    fullBaths
    threeQuarterBaths
    halfBaths
    structure
    construction
    roof
    roofShape
    heatingType
    numberOfStories
    garage
    withinCityLimits
    withinFireDistrict
    distanceToFireHydrant
    distanceToFireStation
    electricCircuitBreaker
    priorPolicy
    effective
    homeReplaceCost
    deductible
    garagesAndCarports
    roofingUpdate
    roofingUpdateYear
    plumbingUpdate
    plumbingUpdateYear
    electricalUpdate
    electricalUpdateYear
    heatingUpdate
    heatingUpdateYear
    smokeDetector
    sprinkler
    fireExtinguisher
    deadBolt
    fireAlarm
    fireAlarmType
    burglarAlarm
    burglarAlarmType
    dogOnPremises
    dogBreedType
    dogBreed
    animalsBite
    swimmingPool
    swimmingPoolFenced
    swimmingPoolType
    airConditioning
    airConditioningType
    priorCarrier
    continuousCoverage
    propertyInsCancelledLapsed
    yearsWithPriorCarrier
    lossInfos {
      amount
      catLoss
      dateOfIncident
      description
    }
    reasonNoPrior
    medicalPayments
    personalProperty
    personalLiability
    hurricaneDeductible
    distanceFromCoastline
    numberOfChildren
  }
`;
export const BASE_QUOTE = gql`
  ${DRIVER_FRAGMENT}
  ${VEHICLE_FRAGMENT}
  ${INCIDENT_FRAGMENT}
  ${DEMOGRAPHIC_FRAGMENT}
  ${HOME_FRAGMENT}
  ${RENTER_FRAGMENT}
  fragment BaseQuote on Quote {
    id
    quoteUuid
    quoteType
    ...BaseDemographic
    incidents {
      ...BaseIncident
    }
    vehicles {
      ...BaseVehicle
    }
    drivers {
      ...BaseDriver
    }
    home {
      ...BaseHome
    }
    renter {
      ...BaseRenter
    }
  }
`;
export const CREATE_QUOTE = gql`
  ${BASE_QUOTE}
  mutation createQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      quote {
        ...BaseQuote
      }
      success
      errors
    }
  }
`;

export const CREATE_PARTNER_SETTINGS = gql`
  mutation CreatePartnerSettings($input: CreatePartnerSettingsInput!) {
    createPartnerSettings(input: $input) {
      partnerSettings {
        subdomain
        font
        logo
        primaryColor
        secondaryColor
      }
      success
      errors
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation updatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      success
      errors
    }
  }
`;
