import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import { useAlerts } from '../state/AlertsContext';
import { useHeap } from '../state/ScriptsDataProvider';
import { useLocation } from '@reach/router';
import { partnersDashboard, reports } from '../../utils/routes';
import { useRedux } from '../redux/reduxContext';

export const setUserData = (authenticationToken, user, identifyUser) => {
  Cookies.set('token', authenticationToken, { secure: true });
  Cookies.set('user', JSON.stringify(user), { secure: true });
  identifyUser({
    email: user?.email,
    id: user.id,
    firstName: user?.firstName,
  });
};

const useOnCompleteLogin = () => {
  const { identifyUser } = useHeap();
  const dispatchAlert = useAlerts();
  const { pathname } = useLocation();
  const reduxDispatcher = useRedux()[1];

  const handleCloseLoginPopup = () => {
    reduxDispatcher({
      type: 'LOGIN_POPUP_ACTION',
      payload: {
        open: false,
      },
    });
  };

  const onCompleteLogin = (
    mutationField,
    noRedirect = false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLoading = () => {},
    errors,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleClose = () => {}
  ) => {
    const {
      success,
      user: { authenticationToken, ...user },
    } = mutationField;

    const redirectToPartnerDash =
      user?.roles.find(item => item.name === 'partner') &&
      pathname.includes(`${partnersDashboard}/`);

    if (authenticationToken && success && !errors) {
      setUserData(authenticationToken, user, identifyUser);
      dispatchAlert(`Welcome ${user.firstName}`, 'info');

      if (redirectToPartnerDash) return handleCloseLoginPopup();
      if (!noRedirect) {
        navigate('/account/dashboard');
      }
      setLoading(false);
      handleClose();
    } else {
      dispatchAlert('Invalid email or password.');
    }
  };
  return onCompleteLogin;
};
export default useOnCompleteLogin;
