import { useMutation } from '@apollo/client';
import { TOKEN_AUTH } from '../../graphql/mutations/mutations';

const useLoginWithToken = () => {
  const [tokenAuth] = useMutation(TOKEN_AUTH);
  const loginWithToken = ({ token, onCompleted, onError }) => {
    tokenAuth({
      variables: { tokenAuthInput: { contactToken: token } },
      onCompleted: data => onCompleted(data),
      onError: error => onError(error),
    });
  };
  return loginWithToken;
};
export default useLoginWithToken;
