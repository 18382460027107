import React from 'react';
import PropTypes from 'prop-types';
import UrlParamsWrapper from '../UrlParamsWrapper';

const wrapPageElement = ({ element }) => {
  return <UrlParamsWrapper>{element}</UrlParamsWrapper>;
};

wrapPageElement.propTypes = {
  element: PropTypes.object,
};

export default wrapPageElement;
