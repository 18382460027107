export const messages = {
  EMAIL: 'This should be an email',
  NUMBER: 'Only numbers allowed',
  VIN: 'Invalid VIN value, must have 17 characters',
  MILEAGE: 'Invalid Mileage value',
  ZIP: 'Invalid Zip code. Must have 5 digits',
  PHONE: 'Phone must have (xxx) xxx-xxxx format',
  YY: 'Only values from 1900-2010 are allowed',
  DD: 'Day is not valid',
  MM: 'Month is not valid',
  INVALID: 'Input is invalid',
  NOT_SELECTED: 'Please select an option',
  BIGGER_THAN_ZERO: 'Should be bigger than 0',
  EMPTY: 'Please provide the required data',
  PASSWORD: 'Password must have at least 8 characters',
};

export const isNumber = str => {
  return /^\d+$/.test(str);
};

/**
 * Determines if a person is at least a certain number of years old based on their birthdate, provided as an ISO 8601 date string.
 *
 * @param {string} isoDate - The birthdate of the person in ISO 8601 format (yyyy-mm-dd).
 * @param {number} [years=18] - The minimum number of years the person should be. Default is 18.
 * @returns {boolean} Returns true if the person is at least the specified number of years old, false otherwise.
 */
export const ageValidator = (isoDate, years = 18) => {
  const birthDate = new Date(isoDate); // Create a Date object from the ISO 8601 date string
  const today = new Date(); // Get today's date
  const differenceInMilliseconds = today - birthDate; // Calculate the difference between today's date and the person's birthdate in milliseconds
  const differenceInYears = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365); // Convert the difference to years

  return differenceInYears >= years; // Return true if the person is at least the specified number of years old, false otherwise
};

export const phoneFormatToNumbers = phone => {
  let parsedPhone = phone
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll(' ', '')
    .replaceAll('+', '')
    .replaceAll('-', '');
  return parsedPhone;
};
export const isEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};
export const isPassword = password => (password.length < 8 ? false : true);

export const hasOnlyLetter = str => {
  return /^[a-zA-Z]+$/.test(str);
};

export const isVin = str => {
  if (isNumber(str) && (str.length === 17 || str.length === 11)) {
    return true;
  }

  return false;
};
export const capitalizeFirst = str => {
  return str[0].toUpperCase() + str.slice(1, str.length);
};
export const isValidMileage = str => {
  if (!isNumber(str)) {
    return false;
  }

  return parseInt(str) >= 1000 && parseInt(str) <= 99999;
};

export const isZipCode = str => {
  return str.match(/^\d{5}(?:\d{4})?$/);
};

export const isPhone = (str = '') => {
  return str?.match(/\d/g)?.join('')?.length === 10;
};

export const isValidDay = (str = '', values = {}) => {
  if (!isNumber(str)) {
    return false;
  }
  const month = values?.['mm'];
  let totalDays = 31;
  if (month == '02') {
    totalDays = 29;
  }
  if (month == '04' || month == '06' || month == '09' || month == '11') {
    totalDays = 30;
  }
  const day = parseInt(str);
  return day >= 1 && day <= totalDays;
};

export const isValidMonth = (str = '') => {
  if (!isNumber(str)) {
    return false;
  }
  const month = parseInt(str);
  return month >= 1 && month <= 12;
};

export const isValidYear = str => {
  if (!isNumber(str)) {
    return false;
  }
  return parseInt(str) >= 1900 && parseInt(str) <= 2010;
};

export const validationTypes = {
  email: {
    validationFunction: isEmail,
  },
  password: {
    validationFunction: isPassword,
  },
  number: {
    validationFunction: isNumber,
  },
  vin: {
    validationFunction: isVin,
  },
  annual_mileage: {
    validationFunction: isValidMileage,
  },
  zip: {
    validationFunction: isZipCode,
  },
  phone: {
    validationFunction: isPhone,
  },
  yy: {
    validationFunction: isValidYear,
  },
  dd: {
    validationFunction: isValidDay,
  },
  mm: {
    validationFunction: isValidMonth,
  },
  minYears: {
    validationFunction: ageValidator,
  },
};

export const emailPhonePasswordValidations = {
  email: {
    type: 'email',
    message: messages.EMAIL,
    isMandatory: true,
  },
  password: {
    type: 'password',
    message: messages.PASSWORD,
    isMandatory: true,
  },
  phone: {
    type: 'phone',
    message: messages.PHONE,
    isMandatory: true,
  },
};

const isNumericInput = event => {
  const numericRegex = /^[0-9]*$/;
  const isNumber = numericRegex.test(event?.key);
  return isNumber;
};

const isModifierKey = e => {
  if (!e) return;
  let allowedKeys = [
    'Home',
    'End',
    'Backspace',
    'Tab',
    'Enter',
    'Delete',
    'ArrowLeft',
    'ArrowUp',
    'ArrowRight',
    'ArrowDown',
  ];
  return !!e.ctrlKey || !!e.metaKey || allowedKeys.includes(e.key);
};

export const enforceFormat = event => {
  if (!event) return;
  // Input must be of a valid number format or a modifier key
  // Allow only numeric characters
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

/**
 * Formats a number as a currency in USD without cents.
 * @param {number} number - The number to format as currency.
 * @returns {string|number} The formatted currency string or the input number if it's not a valid number.
 */
export const formatToCurrency = (number, removeCents = false) => {
  if (!isNaN(number)) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: removeCents ? 0 : 2,
    });
    return formatter.format(number);
  } else return number;
};
export const dateTimeFormat = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
});

export const returnParsedApiString = apiString =>
  JSON.parse(
    apiString
      .replace(/(:)([^":]*)(=>)/g, '"$2":')
      .replaceAll('=>', ':')
      .replaceAll('nil', 'null')
  );

export const formatToPhone = event => {
  const target = event.target;
  let key = event.keyCode || event.charCode;

  // Prevent to add space or parenthesis on delete
  if (key == 8 || key == 46) {
    return target.value;
  }

  const input = event.target.value.replace(/\D/g, '').substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 5) {
    target.value = `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 2) {
    target.value = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    target.value = `(${zip}`;
  }

  return target.value;
};

export const formatToZip = event => {
  const target = event.target;

  if (target.value > 5) {
    target.value = target?.value?.slice(0, 5);
  }

  return target.value;
};
export const getValueForInputTypeDate = date => {
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  let year = date.getFullYear();
  day = day.length == 1 ? `0${day}` : day;
  month = month.length == 1 ? `0${month}` : month;
  return `${year}-${month}-${day}`;
};

export const convertYesNoToBool = value => {
  if (value == 'Yes') {
    return true;
  } else if (value == 'No' || !value) {
    return false;
  }
};

export const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );

export const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });
};

export const base64ToBlob = (base64, mime) => {
  const sliceSize = 1024;
  const byteChars = window.atob(base64);
  const byteArrays = [];

  for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
    const slice = byteChars.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: mime });
  return blob;
};

// This function converts the base64 string to a File object
export const base64ToFile = (base64String, filename) => {
  const [mime] = base64String.match(/^data:(.*);base64/) || [];
  const base64 = base64String.replace(/^data:.*;base64,/, '');
  const blob = base64ToBlob(base64, mime);
  return new File([blob], filename, { type: mime });
};
